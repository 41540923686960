<!-- System: STA
    Purpose: This compoment will display account settings
            page, where user can update his/her information
-->
<template>
  <div>
    <v-card max-width="1100" class="mt-15 mb-2 col-12 mx-auto">
      <div class="information-card">
        <!-- Account Setting Form -->
        <v-form
          @reset="onReset"
          @submit="onSubmit"
          autocomplete="off"
          ref="account_form"
        >
          <div class="row form-div unset-margin info-spacing">
            <div class="col-md-12 col-lg-12 col-xl-12 img-col mt-10">
              <!-- When web cam is in use -->
              <div v-if="startCam">
                <div class="start-camera text-center">
                  <div>
                    <web-cam
                      :deviceId="deviceId"
                      @cameras="onCameras"
                      @error="onError"
                      height="250px"
                      ref="webcam"
                      width="100%"
                    />
                  </div>
                  <div class="row">
                    <div class="col-md-12" style="padding-bottom: 20px">
                      <button
                        @click="onCapture"
                        class="my-btn-img text-center mt-2"
                        type="button"
                      >
                        Capture Photo
                      </button>
                    </div>
                    <div class="col-md-12" style="padding-bottom: 30px">
                      <button
                        @click="cancelWebUpload"
                        class="my-btn-img text-center mt-2"
                        type="button"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- When web cam not in use -->
              <div
                class="row ml-0 mr-0 align-items-center displayImage text-center"
                v-if="!startCam"
              >
                <!-- upload image div -->
                <div class="text-center" v-if="this.form.upload_src">
                  <label class="fileContainer">
                    <div class="img-text">
                      <b style="color: #2758f6; font-weight: 100"
                        >Upload an image</b
                      >
                      <br />upto the size of 1MB Dimensions 150 x 150
                    </div>
                    <input
                      @change="onFileChange"
                      accept="image/png, image/jpg, image/jpeg"
                      data-vv-as="Image"
                      name="logo"
                      type="file"
                    />
                  </label>
                  <!-- upload from webcam button -->
                  <button
                    @click="openWebCam"
                    class="my-btn-img text-center mt-3"
                    type="button"
                  >
                    Upload From WebCam
                  </button>
                </div>
                <!-- User display image  -->
                <div v-if="form.image_source">
                  <!-- loading div -->
                  <div
                    class="spinner-border text-primary spinner-set"
                    role="status"
                    v-if="showSpinner"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <!-- user image display-->
                  <img
                    :src="cropped_file_url"
                    @error="
                      $event.target.src = require('@/assets/images/no-member.svg')
                    "
                    class="user-image"
                    v-bind:class="{ blur: showSpinner }"
                  />
                  <!-- change image button -->
                  <div class="margin-button">
                    <button
                      @click="removeImage"
                      class="my-btn-img text-center mt-3"
                      type="button"
                    >
                      Change Image
                    </button>
                  </div>
                </div>
                <!-- Cropper image div -->
                <div v-if="form.crop_src">
                  <!-- Cancel button -->
                  <div class="button-margin">
                    <button
                      @click="cancelUpload"
                      class="my-btn-img button-width text-center mt-4"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>

                  <!-- Image cropper -->
                  <imageCroper
                    :cropHeight="150"
                    :cropWidth="150"
                    :imgSrc="form.image_src"
                    :imgStyle="{ width: '250px', height: '250px' }"
                    :resizeable="false"
                    @croped_file="attach_crop_file($event)"
                    alt="User Image"
                    v-if="this.uploaded"
                  />
                </div>
                <!-- User display image preview  -->
                <div v-if="form.image_preview">
                  <!-- loading div -->
                  <div
                    class="spinner-border text-primary spinner-set"
                    role="status"
                    v-if="showSpinner"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <!-- user preview image display-->
                  <img
                    :src="cropped_file"
                    @error="
                      $event.target.src = require('@/assets/images/no-member.svg')
                    "
                    class="user-image"
                    v-bind:class="{ blur: showSpinner }"
                  />
                  <!-- success image -->
                  <img
                    class="success-tick"
                    height="30"
                    src="../../assets/images/success-image.svg"
                    width="30"
                  />
                  <!-- change image button -->
                  <div class="margin-button">
                    <button
                      @click="removeImage"
                      class="my-btn-img text-center mt-3"
                      type="button"
                    >
                      Change Image
                    </button>
                  </div>
                </div>
                <!-- Error div-->
                <div class="text-center">
                  <span class="error-msg ml-2">{{ errorMsg }}</span>
                </div>
              </div>
            </div>
            <!-- User personal information -->
            <div
              id="personal-info"
              class="col-md-12 col-lg-12 col-xl-12 main-div-account"
            >
              <v-chip
                class="account-setting mb-10"
                color="rgba(203,218,242,0.24)"
                >Personal Information</v-chip
              >
              <div class="row unset-margin info-spacing">
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <!-- Name Text Field -->
                  <v-text-field
                    outlined
                    v-if="keyData != null && Keys === keyData"
                    disabled
                    name="name"
                    label="Name"
                    required
                    type="text"
                    v-model="form.name"
                  ></v-text-field>
                  <v-text-field
                    v-else
                    outlined
                    name="name"
                    label="Name"
                    :rules="UserNameRules"
                    required
                    type="text"
                    v-model="form.name"
                  ></v-text-field>
                </div>
                <!-- Email Text Field -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <v-text-field
                    outlined
                    autocomplete="username"
                    disabled
                    name="email"
                    label="Email"
                    type="email"
                    v-model="email"
                  ></v-text-field>
                </div>
                <!-- Phone Text Field -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <v-text-field
                    outlined
                    name="phone_number"
                    label="Phone Number *"
                    :rules="phoneNumberRules"
                    required
                    type="text"
                    v-model="form.phone_number"
                  ></v-text-field>
                </div>
              </div>
              <v-chip
                class="account-setting mb-10"
                color="rgba(203,218,242,0.24)"
                v-if="
                  this.$route.params.id &&
                  selectedCompanyUserStatus !== 'employee'
                "
              >
                Company Information
              </v-chip>
              <div
                class="row unset-margin info-spacing"
                v-if="
                  this.$route.params.id &&
                  selectedCompanyUserStatus !== 'employee'
                "
              >
                <!-- Company Name Text Field -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <v-text-field
                    outlined
                    data-vv-as="Company name"
                    name="company_name"
                    label="Company Name"
                    :rules="companyNameRules"
                    type="text"
                    v-model="form.company_name"
                  ></v-text-field>
                </div>
                <!-- Team Size Text Field -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <v-text-field
                    outlined
                    data-vv-as="Team size"
                    name="team_size"
                    label="Company Size"
                    :rules="TeamSize"
                    type="text"
                    v-model="form.team_size"
                  ></v-text-field>
                </div>
                <!-- Company Website Text Field -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <v-text-field
                    outlined
                    autocomplete="company-website"
                    data-vv-as="Company website"
                    name="website"
                    label="Company Website"
                    type="text"
                    v-model="form.website"
                  ></v-text-field>
                </div>
              </div>
              <!-- Select Industry Dropdown -->
              <div
                class="row unset-margin info-spacing"
                v-if="
                  $route.params.id && selectedCompanyUserStatus !== 'employee'
                "
              >
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <label>Industry</label>
                  <v-autocomplete
                    v-model="form.industry"
                    :items="industries"
                    outlined
                    label="Industry"
                    persistent-hint
                    return-object
                    single-line
                    hide-selected
                  ></v-autocomplete>
                </div>
                <!-- Select Country Dropdown -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <label>Countries</label>
                  <v-autocomplete
                    outlined
                    class="form-control no-border-radius select2 custom-select"
                    placeholder="Select Country"
                    v-model="form.country"
                    :items="countries"
                  >
                  </v-autocomplete>
                </div>
                <!-- Select Time Zone Dropdown -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <label>Time Zone</label>
                  <v-autocomplete
                    outlined
                    class="text-left"
                    placeholder="Select time Zone"
                    v-model="form.timezone"
                    :items="time_zones"
                    item-text="name"
                    item-value="value"
                  >
                  </v-autocomplete>
                </div>
                <!-- Departments -->
                <div
                  class="col-md-12 col-lg-7 col-xl-7 col-sm-12 text-left d-none"
                >
                  <div id="department">
                    <v-text-field
                      outlined
                      type="text"
                      label="Add Department"
                      title="Department Name"
                      @click="show_department"
                      v-model="EditDept"
                      class="edit-margin edit-seprate2 custom-department add_select_dep"
                      readonly
                    />
                    <img
                      class="image-dep"
                      src="../../assets/images/dropdownarrow.svg"
                      @click="show_department"
                    />
                    <div class="department" v-if="check_department === true">
                      <input
                        outlined
                        type="text"
                        class="department-input"
                        id="department_add"
                        v-model.trim="search_add_department"
                        name="deaprtment"
                        label="Add New Department"
                      />
                      <img
                        class="image-icon"
                        @click="add_department_new"
                        title="Type and click + Button to Add New Department"
                        src="../../assets/images/plus_department.svg"
                      />
                      <div class="department_dev">
                        <!-- <span class="label-department">Departments</span> -->
                      </div>
                      <ul style="list-style: none" class="scroll-department">
                        <li
                          class="list-department text-left"
                          v-for="department in departments"
                          :class="EditDept === department.name"
                          :key="department.hash_id"
                        >
                          {{ department.name }}
                        </li>
                        <li
                          v-if="departments.length == 0"
                          class="list-department"
                        >
                          No Result Found
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Update Password -->
              <div class="row"></div>
              <v-chip
                class="account-setting mb-10"
                color="rgba(203,218,242,0.24)"
                >Change Password</v-chip
              >
              <div class="row unset-margin info-spacing">
                <!-- Old Password Text Field -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <v-text-field
                    outlined
                    autocomplete="current-password"
                    name="old_password"
                    label="Old Password"
                    :rules="passwordRules"
                    type="password"
                    v-model="form.old_password"
                  ></v-text-field>
                </div>
                <!-- New Password Text Field -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <v-text-field
                    outlined
                    autocomplete="new-password"
                    data-vv-as="New Password"
                    name="password"
                    :rules="passwordRules"
                    label="New Password"
                    ref="password"
                    type="password"
                    v-model="form.password"
                  ></v-text-field>
                </div>
                <!-- Confrim Password Text Field -->
                <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 text-left">
                  <v-text-field
                    outlined
                    autocomplete="new-password"
                    data-vv-as="Confirm Password"
                    name="password_confirmation"
                    :rules="passwordRules"
                    label="Confirm Password"
                    type="password"
                    v-model="form.password_confirmation"
                  ></v-text-field>
                </div>
              </div>
              <!-- Buttons -->
              <div class="row"></div>
              <div
                class="row mt-3 pb-3 float-right btn-row unset-margin button-save"
              >
                <v-btn
                  large
                  color="#2758f6"
                  v-if="$route.params.id"
                  outlined
                  @click="back()"
                  class="save-setting-btn"
                  style="margin-right: 7px"
                  type="button"
                >
                  Back
                </v-btn>

                <v-btn
                  large
                  color="#2758f6"
                  v-else
                  @click="back_to_companies()"
                  class="save-setting-btn"
                  style="margin-right: 7px"
                  type="button"
                >
                  Back
                </v-btn>
                <v-btn
                  large
                  color="#2758f6"
                  class="save-setting-btn"
                  ref="submit"
                  type="submit"
                  :disabled="btnLoader"
                >
                  <v-progress-circular indeterminate v-if="btnLoader" size="20">
                  </v-progress-circular>
                  Save
                </v-btn>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </v-card>
    <!-- Delete Account Footer -->
    <div class="deactivate-acc mb-5 mt-4 pl-sm-0 pl-2">
      <h5>Delete Your Account</h5>
      <p class="p-text mb-5">
        If you delete your account, all of your personal, financial and any
        other information saved on our servers including companies, projects,
        tasks & screenshots, will be deleted.
        <v-dialog v-model="deactiate_dialog" max-width="490">
          <template v-slot:activator="{ on, attrs }">
            <a v-bind="attrs" v-on="on">Delete Your Account</a>
          </template>
          <v-card>
            <v-toolbar color="primary" class="headline" dark
              >Are you sure?</v-toolbar
            >
            <v-card-text class="text-justify pa-4"
              >We are sorry to see you go. Before you move to the deletion
              process <b>please note:</b> All your data associated Companies,
              Team Members and Projects will no longer be viewable or accessible
              and permanently deleted.</v-card-text
            >
            <!-- Delete Account Actions -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                text
                @click="deactiate_dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                outlined
                text
                @click.prevent="deactivateUser"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </p>
    </div>
    <br />
    <br />
  </div>
</template>
<script>
import {
  UserNameRules,
  phoneNumberRules,
  companyNameRules,
  TeamSize,
  passwordRules,
} from "@/Constants/Const";
import { sha256 } from "js-sha256";
import { WebCam } from "vue-web-cam";
import { find, head } from "lodash";
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "AccSettings",
  mounted() {
    this.get_user_info();
    // this.get_department_list();
  },
  destroyed() {
    window.location.reload();
  },
  components: {
    WebCam,
    imageCroper: () => import("@/components/includes/helper/ImageCropper"),
  },
  props: ["userSessionsData"],
  data() {
    return {
      UserNameRules: UserNameRules,
      phoneNumberRules: phoneNumberRules,
      companyNameRules: companyNameRules,
      TeamSize: TeamSize,
      passwordRules: passwordRules,
      deactiate_dialog: false,
      btnLoader: false,
      errorMsg: "",
      uploaded: false,
      image_cropper: true,
      fields: [],
      is_cropped: false,
      cropped_file_url: require("../../assets/images/no-member.svg"),
      showSpinner: false,
      startCam: false,
      captureImage: false,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      email: null,
      message: "upload",
      picturePath: "",
      uploadResult: "",
      upError: "",
      form: {
        image_preview: false,
        crop_src: false,
        upload_src: false,
        image_source: true,
        base64: "",
        phone_number: "",
        name: "",
        country: "",
        industry: "",
        timezone: "",
        company_name: "",
        team_size: "",
        idle_time_deduction: false,
        website: "",
        old_password: "",
        password: "",
        password_confirmation: "",
        imageUrl: null,
        image_src: require("../../assets/images/no-member.svg"),
        currency: "",
      },
      currencies: [],
      list_department: [],
      EditDept: "",
      check_department: false,
      search_add_department: null,
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua & Deps",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Central African Rep",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo {Democratic Rep}",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland {Republic}",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea North",
        "Korea South",
        "Kosovo",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar, {Burma}",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "St Kitts & Nevis",
        "St Lucia",
        "Saint Vincent & the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome & Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tonga",
        "Trinidad & Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican City",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      industries: [
        "Accounting",
        "Advertising / Marketing / PR",
        "Architecture",
        "Construction",
        "Consulting - IT / Engineering",
        "Consulting - IT / Management",
        "Consulting - IT / Healthcare",
        "Consulting - IT / VAR / System Integration",
        "Financial Services",
        "Legal",
        "Medical / Pharma / Biotech",
        "Web Design",
        "Web Development",
        "Writers",
        "Journalists",
        "BOP",
        "Call Center",
        "Others",
      ],
      time_zones: [
        {
          name: "(GMT-12:00) International Date Line West",
          value: "International Date Line West",
        },
        {
          name: "(GMT-11:00) Midway Island, Samoa",
          value: "Midway Island, Samoa",
        },
        { name: "(GMT-10:00) Hawaii", value: "Hawaii" },
        { name: "(GMT-09:00) Alaska", value: "Alaska" },
        {
          name: "(GMT-08:00) Pacific Time (US & Canada)",
          value: "Pacific Time (US & Canada)",
        },
        {
          name: "(GMT-08:00) Tijuana, Baja California",
          value: "Tijuana, Baja California",
        },
        { name: "(GMT-07:00) Arizona", value: "Arizona" },
        {
          name: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
          value: "Chihuahua, La Paz, Mazatlan",
        },
        {
          name: "(GMT-07:00) Mountain Time (US & Canada)",
          value: "Mountain Time (US & Canada)",
        },
        { name: "(GMT-06:00) Central America", value: "Central America" },
        {
          name: "(GMT-06:00) Central Time (US & Canada)",
          value: "Central Time (US & Canada)",
        },
        {
          name: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
          value: "Guadalajara, Mexico City, Monterrey",
        },
        { name: "(GMT-06:00) Saskatchewan", value: "Saskatchewan" },
        {
          name: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
          value: "Bogota, Lima, Quito, Rio Branco",
        },
        {
          name: "(GMT-05:00) Eastern Time (US & Canada)",
          value: "Eastern Time (US & Canada)",
        },
        { name: "(GMT-05:00) Indiana (East)", value: "Indiana (East)" },
        {
          name: "(GMT-04:00) Atlantic Time (Canada)",
          value: "Atlantic Time (Canada)",
        },
        { name: "(GMT-04:00) Caracas, La Paz", value: "Caracas, La Paz" },
        { name: "(GMT-04:00) Manaus", value: "Manaus" },
        { name: "(GMT-04:00) Santiago", value: "Santiago" },
        { name: "(GMT-03:30) Newfoundland", value: "Newfoundland" },
        { name: "(GMT-03:00) Brasilia", value: "Brasilia" },
        {
          name: "(GMT-03:00) Buenos Aires, Georgetown",
          value: "Buenos Aires, Georgetown",
        },
        { name: "(GMT-03:00) Greenland", value: "Greenland" },
        { name: "(GMT-03:00) Montevideo", value: "Montevideo" },
        { name: "(GMT-02:00) Mid-Atlantic", value: "Mid-Atlantic" },
        { name: "(GMT-01:00) Cape Verde Is", value: "Cape Verde Is." },
        { name: "(GMT-01:00) Azores", value: "Azores" },
        {
          name: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
          value: "Casablanca, Monrovia, Reykjavik",
        },
        {
          name:
            "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh,Lisbon, London",
          value: "Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
        },
        {
          name: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm,Vienna",
          value: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        },
        {
          name: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana,Prague",
          value: "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        },
        {
          name: "Brussels, Copenhagen, Madrid, Paris",
          value: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
        },
        {
          name: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
          value: "Sarajevo, Skopje, Warsaw, Zagreb",
        },
        {
          name: "(GMT+01:00) West Central Africa",
          value: "West Central Africa",
        },
        { name: "(GMT+02:00) Amman", value: "Amman" },
        {
          name: "(GMT+02:00) Athens, Bucharest, Istanbul",
          value: "Athens, Bucharest, Istanbul",
        },
        { name: "(GMT+02:00) Beirut", value: "Beirut" },
        { name: "(GMT+02:00) Cairo", value: "Cairo" },
        { name: "(GMT+02:00) Harare, Pretoria", value: "Harare, Pretoria" },
        {
          name: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn,Vilnius",
          value: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        },
        { name: "(GMT+02:00) Jerusalem", value: "Jerusalem" },
        { name: "(GMT+02:00) Minsk", value: "Minsk" },
        { name: "(GMT+02:00) Windhoek", value: "Windhoek" },
        {
          name: "(GMT+03:00) Kuwait, Riyadh, Baghdad",
          value: "Kuwait, Riyadh, Baghdad",
        },
        {
          name: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
          value: "Moscow, St. Petersburg, Volgograd<",
        },
        { name: "(GMT+03:00) Nairobi", value: "Nairobi" },
        { name: "(GMT+03:00) Tbilisi", value: "Tbilisi" },
        { name: "(GMT+03:30) Tehran", value: "Tehran" },
        { name: "(GMT+04:00) Abu Dhabi, Muscat", value: "Abu Dhabi, Muscat" },
        { name: "(GMT+04:00) Baku", value: "Baku" },
        { name: "(GMT+04:00) Yerevan", value: "Yerevan" },
        { name: "(GMT+04:30) Kabul", value: "Kabul" },
        { name: "(GMT+05:00) Yekaterinburg", value: "Yekaterinburg" },
        {
          name: "(GMT+05:00) Islamabad, Karachi, Tashkent",
          value: "Islamabad, Karachi, Tashkent",
        },
        {
          name: "(GMT+05:30) Sri Jayawardenapura",
          value: "Sri Jayawardenapura",
        },
        {
          name: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
          value: "Chennai, Kolkata, Mumbai, New Delhi",
        },
        { name: "(GMT+05:45) Kathmandu", value: "Kathmandu" },
        {
          name: "(GMT+06:00) Almaty, Novosibirsk",
          value: "Almaty, Novosibirsk<",
        },
        { name: "(GMT+06:00) Astana, Dhaka", value: "Astana, Dhaka" },
        { name: "(GMT+06:30) Yangon (Rangoon)", value: "Yangon (Rangoon)" },
        {
          name: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
          value: "Bangkok, Hanoi, Jakarta",
        },
        { name: "(GMT+07:00) Krasnoyarsk", value: "Krasnoyarsk" },
        {
          name: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
          value: "Beijing, Chongqing, Hong Kong, Urumqi",
        },
        {
          name: "(GMT+08:00) Kuala Lumpur, Singapore",
          value: "Kuala Lumpur, Singapore",
        },
        {
          name: "(GMT+08:00) Irkutsk, Ulaan Bataar",
          value: "Irkutsk, Ulaan Bataar",
        },
        { name: "(GMT+08:00) Perth", value: "Perth" },
        { name: "(GMT+08:00) Taipei", value: "Taipei" },
        {
          name: "(GMT+09:00) Osaka, Sapporo, Tokyo",
          value: "Osaka, Sapporo, Tokyo",
        },
        { name: "(GMT+09:00) Seoul", value: "Seoul" },
        { name: "(GMT+09:00) Yakutsk", value: "Yakutsk" },
        { name: "(GMT+09:30) Adelaide", value: "Adelaide" },
        { name: "(GMT+09:30) Darwin", value: "Darwin" },
        { name: "(GMT+10:00) Brisbane", value: "Brisbane" },
        {
          name: "(GMT+10:00) Canberra, Melbourne, Sydney",
          value: "Canberra, Melbourne, Sydney",
        },
        { name: "(GMT+10:00) Hobart", value: "Hobart" },
        { name: "(GMT+10:00) Guam, Port Moresby", value: "Guam, Port Moresby" },
        { name: "(GMT+10:00) Vladivostok", value: "Vladivostok" },
        {
          name: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
          value: "Magadan, Solomon Is., New Caledonia",
        },
        {
          name: "(GMT+12:00) Auckland, Wellington",
          value: "Auckland, Wellington",
        },
        {
          name: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
          value: "Fiji, Kamchatka, Marshall Is.",
        },
        { name: "(GMT+13:00) Nuku'alofa", value: "Nuku'alofa" },
      ],
    };
  },
  computed: {
    ...mapState("custom", ["selected_company", "companies_list"]),
    selectedCompanyUserStatus() {
      if (this.$route.name === "AccountSettingsRoot") return "employee";

      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    Keys() {
      return this.$store.state.pf.keysData[0];
    },
    keyData() {
      if (this.$route.params.id) {
        return sha256(this.$route.params.id);
      }
      return null;
    },
    isInvalid() {
      return this.$v.$invalid;
    },
    device: function () {
      return find(this.devices, (n) => n.deviceId == this.deviceId);
    },
    departments() {
      let department = this.list_department;
      if (this.search_add_department) {
        department = this.list_department.filter((department) =>
          department.name
            .toLowerCase()
            .includes(this.search_add_department.toLowerCase())
        );
      }
      return department;
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      let first = head(this.devices);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    /**
     * This function is resposible for
     * image validation
     */
    isImageValid(imageBase64) {
      if (!this.$socket.connected) {
        this.showSpinner = false;
        this.uploadResult =
          "Oops! There was a connectivity issue. Please try again in few moments.";
        this.upError = this.uploadResult;
        this.cropped_file_url = null;
      } else {
        let parts = imageBase64.split(";");
        let FaceApiBase64 = parts[1].split(",")[1];

        return this.$socket.emit("is_image_valid", FaceApiBase64, (data) => {
          this.uploadResult = data;
          this.showSpinner = false;
        });
      }
    },
    /**
     * This function is resposible for
     * image capturing
     */
    onCapture() {
      this.form.image_src = this.$refs.webcam.capture();
      this.form.base64 = this.$refs.webcam.capture();
      this.uploaded = true;
      this.form.upload_src = false;
      this.form.image_source = false;
      this.form.crop_src = true;
      this.onStop();
      this.startCam = false;
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onError() {
      try {
        this.onFileChange();
      } catch (error) {
        this.startCam = false;
      }
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    url() {
      return baseURL.API_URL;
    },
    /**
     * This function is resposible for
     * Submitting the form data of account
     * setting and api call
     */
    onSubmit(evt) {
      this.btnLoader = true;
      evt.preventDefault();
      this.errorMsg = "";
      this.form.upload_src = false;
      this.form.crop_src = false;
      this.form.image_source = true;
      this.form.image_preview = false;
      if (this.form.phone_number == "") {
        this.$root.$emit("snack_bar", {
          show: true,
          message: "Phone Number Required",
          snackbarColor: "red",
        });
        this.btnLoader = false;

        return;
      }
      let formData = new FormData(this.$refs.account_form.$el);
      formData.append("company_id", this.$route.params.id);
      formData.append("image", this.picturePath);
      formData.append("base64", this.form.base64);
      formData.append("country", this.form.country);
      formData.append("timezone", this.form.timezone);
      formData.append("industry", this.form.industry);
      formData.append("currency_hash_id", this.form.currency);
      this.$store
        .dispatch("custom/account_info_form", formData)
        .then((response) => {
          this.btnLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });

            this.$store.commit("auth/set_user", response.data.user);
            this.onReset();
            this.get_user_info();
            if (this.device != null) {
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          this.btnLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (error.response.data.errors)
            this.$root.$emit("snack_bar", {
              show: true,
              message: Object.values(error.response.data.errors)[0][0],
              snackbarColor: "red",
            });
          else if (error.response.data.error)
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
        });
    },
    /**
     * This function is resposible for
     * resting the form data
     */
    onReset() {
      this.form.stAdress = "";
      this.form.city = "";
      this.form.zipCode = "";
      this.form.vat = "";
      this.form.phNumber = "";
      this.form.countries = [];
      this.form.old_password = "";
      this.form.password = "";
      this.form.password_confirmation = "";
      this.form.idle_time_deduction = false;
      /* Trick to reset/clear native browser form validation state */
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    /**
     * This function is resposible for
     * fetching the user info
     */
    get_user_info() {
      this.$store
        .dispatch("custom/get_user_account_info", {
          company_id: this.$route.params.id,
        })
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);

          this.fields = [
            { key: "device", label: "Device ", class: "text-center" },
            { key: "created_at", label: "Time", class: "text-center" },
            { key: "actions", label: "Actions", class: "text-center" },
          ];
          this.update_form(response.data.auth_user);
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
    update_form(user_info) {
      this.email = user_info.email;
      this.form.phone_number = user_info.phone_number;
      this.form.name = user_info.name;
      this.form.country = user_info.country;
      this.form.industry = user_info.industry;
      this.form.timezone = user_info.timezone;
      this.form.company_name = user_info.company_name;
      this.form.team_size = user_info.team_size;
      this.form.website = user_info.website;
      this.form.idle_time_deduction = user_info.idle_time_deduction;
      this.currencies = user_info.currencies;
      this.form.currency = user_info.currency_hash_id;
      if (!user_info.image.startsWith("https://"))
        this.cropped_file_url = user_info.image
          ? this.url() + "storage/" + user_info.image
          : require("../../assets/images/no-member.svg");
      else
        this.cropped_file_url = user_info.image
          ? user_info.image
          : require("../../assets/images/no-member.svg");
    },
    /**
     * This function is resposible for
     * Image Uploading
     */
    onFileChange(e) {
      this.errorMsg = "";
      this.form.upload_src = false;
      this.form.image_source = false;
      this.form.crop_src = true;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let _size = file.size;
      if (_size > 1000000) {
        this.errorMsg = " Image exceed size limit, try again";
        this.form.crop_src = false;
        this.form.upload_src = true;
        return;
      }
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.form.image_src = e.target.result;
        this.uploaded = true;
      };
      this.picturePath = file;
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.form.image_source = false;
      this.form.upload_src = true;
      this.form.image_preview = false;
      this.form.image_src = "";
      this.uploaded = false;
    },
    cancelUpload: function () {
      this.form.crop_src = false;
      this.form.image_source = true;
      this.form.image_src = "";
      this.uploaded = false;
    },
    cancelWebUpload: function () {
      this.startCam = false;
      this.form.image_source = true;
      this.form.upload_src = false;
    },

    checkPFCompany() {
      return this.$store.state.pf.companies.includes(this.$route.params.id)
        ? true
        : false;
    },
    attach_crop_file(file_url) {
      this.form.crop_src = false;
      this.form.image_preview = true;
      this.uploadResult = "Please wait...!";
      this.showSpinner = true;
      let file_path = this.dataURLtoFile(file_url, "user_image");
      this.cropped_file = file_url;
      this.picturePath = file_path;
      this.is_cropped = true;

      if (this.$store.state.pf.companies.includes(this.$route.params.id))
        this.isImageValid(this.cropped_file);
      else {
        this.uploadResult = true;
        this.showSpinner = false;
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    openWebCam() {
      this.errorMsg = "";
      this.startCam = true;
      setTimeout(() => this.$refs.webcam.start(), 50);
    },
    deactivateUser() {
      this.$store.dispatch("auth/deactivateUser").then((response) => {
        this.deactiate_dialog = false;
        if (response.data.error === false) {
          this.$store.commit("custom/resetState");
          this.$store.dispatch("auth/reset").then(() => {
            window.location.reload(true);
          });
        }
      });
    },
    back() {
      return this.$router.push({
        name: "Dashboard",
        params: { id: this.$route.params.id },
      });
      //return this.$router.go ( - 1 );
    },
    back_to_companies() {
      return this.$router.push({
        name: "CompaniesListing",
      });
    },
    /**
     * This function is resposible for
     * fetching the deparment list
     */
    get_department_list() {
      if (this.selectedCompanyUserStatus == "employee") {
        return;
      } else {
        let data = {
          company_id: this.$route.params.id,
        };
        this.$store
          .dispatch("custom/getDeaprtmentList", data)
          .then((response) => {
            this.$store.commit("custom/toggle_loader", false);
            this.list_department = _.orderBy(
              response.data.department,
              ["name"],
              ["asc"]
            );
          })
          .catch((error) => {
            this.$store.commit("custom/toggle_loader", false);
            if (error.response) {
              if (error.response.data.message === "Unauthenticated.") {
                this.$store.commit("custom/resetState");
                this.$store.dispatch("auth/reset").then(() => {
                  return this.$router.push({ name: "login" });
                });
              }
            }
          });
      }
    },
    show_department() {
      if (this.check_department === true) {
        this.check_department = false;
      } else {
        this.check_department = true;
      }
    },
    /**
     * This function is resposible for
     * adding new department
     */
    add_department_new() {
      if (!this.search_add_department)
        return this.$root.$emit("snack_bar", {
          show: true,
          message: "Department name is required",
          snackbarColor: "red",
        });
      let checkDepartment = this.list_department.find(
        (department) =>
          department.name.toLowerCase() ==
          this.search_add_department.toLowerCase()
      );
      if (checkDepartment) {
        return this.$root.$emit("snack_bar", {
          show: true,
          message: "Department name is already exists",
          snackbarColor: "red",
        });
      }
      let data = {
        department_name: this.search_add_department,
        company_id: this.$route.params.id,
      };
      this.$store
        .dispatch("custom/search_add_department", data)
        .then((response) => {
          this.search_add_department = null;
          var new_department = {};
          new_department.hash_id = response.data.department.hash_id;
          this.$store.state.custom.departments.push(response.data.department);
          this.$store.state.custom.company_department_list.push(
            response.data.department
          );
          new_department.name = response.data.department.name;
          this.list_department.unshift(new_department);
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Department added successfully",
            snackbarColor: "green",
          });
        })
        .catch((error) => {
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.response.data.error_msg,
            snackbarColor: "red",
          });
        });
    },
  },
};
</script>
<style scoped>
select {
  min-width: 100% !important;
}

.displayImage {
  margin: 0 auto;
  display: block;
}

.custom-file {
  width: auto;
}

.error-msg {
  color: #e23719;
  font-size: 12px;
}

.custom-file .custom-file-label {
  background: blue;
  color: #fff;
  border-radius: 0;
  border-color: blue;
}

.custom-file-label:after {
  content: none;
}

.p-text {
  font-size: 12px;
  padding-top: 2px;
}
label.fileContainer [type="file"] {
  display: block;
  filter: alpha(opacity=0);
  opacity: 0;
  text-align: right;
  cursor: pointer;
}

label.fileContainer .img-text {
  margin-top: 39px;
}

label.fileContainer {
  display: block;
  overflow: hidden;
  position: relative;
  font-size: 13px;
  border: dashed 2px #cbdaf2;
  padding: 42px 6px;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  background-image: url("../../assets/images/dashboard_icons/user_profile.svg");
  background-repeat: no-repeat;
}

.fileContainer:hover {
  cursor: pointer !important;
  transform: scale(1.02);
}

.user-image {
  width: 150px;
  height: 150px;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 50%;
}

.start-camera video {
  width: 150px;
  height: 150px;
  -o-object-fit: fill;
  object-fit: fill;
  border-radius: 50%;
}

.deactivate-acc {
  max-width: 1100px;
  color: #88a1ca;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  margin: auto;
}

.deactivate-acc h5 {
  font-size: 18px;
  font-weight: 600;
  color: #36454f;
  white-space: nowrap;
}

.deactivate-acc a {
  color: #2758f6;
}

.start-camera h3 {
  font-size: 20px;
  text-align: center;
}
@media (max-width: 1024px) {
  .card-body .v-form-group {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .card {
    width: 100% !important;
  }
}
@media (max-width: 601px) {
  .setting-main-div .card-body .v-form-group {
    padding-left: 0px !important;
  }
  .save-setting-btn {
    width: 30% !important;
  }
  .save-setting-btn {
    margin-right: 0px !important;
  }
  .save-setting-btn {
    width: 71% !important;
    margin-right: 13px !important;
    height: 36px;
    font-size: 17px;
  }
}
@media (max-width: 480px) {
  .btn-row .save-setting-btn {
    width: 40% !important;
  }
}
@media (max-width: 375px) {
  .btn-row .save-setting-btn {
    width: 40% !important;
    height: 35px;
    margin-bottom: 8px;
    font-size: 16px;
  }
}
.img-col {
  margin: inherit;
}

.my-btn-img {
  height: 32px;
  border: 0.75px solid #2758f6;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(165, 165, 165, 0.23);
  color: #2758f6;
  margin-bottom: 0rem !important;
  font-size: 14px;
  white-space: nowrap;
  padding: 0px 10px;
}

.my-btn-img:hover {
  color: #2758f6;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5f5f5 !important;
  opacity: 1;
}

@media (max-width: 1199px) {
  .unset-margin {
    margin-left: unset !important;
  }

  .card-body {
    padding-right: 44px;
  }
}

@media (max-width: 550px) {
  .card-body {
    padding-right: 27px;
    padding-left: unset;
  }
}

.form-div input {
  /* height: 48px !important; */
  border: 1px solid #cbdaf2;
  border-radius: 3px !important;
  font-size: 14px !important;
}

.form-div input:hover {
  border: 1px solid #2758f6 !important;
}

.form-group label {
  color: #36454f;
  font-family: "Poppins", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
}

.account-setting {
  margin-top: 10px;
}

h5.account-setting {
  color: #36454f;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.card {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 9px 0 rgba(196, 196, 196, 0.35);
}

.save-setting-btn {
  height: 50px;
  width: 142px;
  border-radius: 3px !important;
  /* background-color: #2758f6; */
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin-top: 3px;
  margin-bottom: 15px;
}

.save-setting-btn-disabled {
  height: 50px;
  width: 142px;
  border-radius: 3px !important;
  background-color: #2758f6;
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin-top: 3px;
  margin-bottom: 15px;
  border: unset;
  cursor: no-drop;
}

.save-setting-btn:hover {
  color: white;
  box-shadow: 0px 5px 12px 0px rgba(23, 70, 222, 0.4) !important;
  transform: scale(1.01);
  /* background-color: #2758f6 !important; */
  cursor: pointer !important;
}

@media (max-width: 991px) {
  .save-setting-btn {
    width: 26% !important;
    margin-right: 13px !important;
    height: 41px;
    font-size: 17px;
  }

  .save-setting-btn-disabled {
    width: 98% !important;
  }

  .btn-row {
    width: 100% !important;
    float: none !important;
  }
  #personal-info {
    padding: 0 0px !important;
  }
  .info-spacing {
    margin-right: 0px;
  }
  .information-card {
    padding: 30px 20px !important;
  }
}

@media (max-width: 767px) {
  .deactivate-acc {
    max-width: 100%;
  }

  h5.account-setting {
    font-size: 15px;
  }

  .deactivate-acc h5 {
    font-size: 15px;
  }

  .form-group label {
    font-size: 12px;
  }

  .img-col {
    margin: unset;
  }
}

@media (max-width: 1023px) and (min-width: 768px) {
  .deactivate-acc {
    max-width: 100%;
  }

  h5.account-setting {
    font-size: 15px;
  }

  .deactivate-acc h5 {
    font-size: 15px;
  }

  .form-group label {
    font-size: 12px;
  }

  .img-col {
    margin: unset;
  }
}

.spinner-set {
  position: relative;
  top: 9px !important;
  left: 93px !important;
  z-index: 99;
}

@media (max-width: 320px) {
  .spinner-set {
    position: relative;
    top: 99px !important;
    left: 2px !important;
    z-index: 99;
  }

  .blur {
    margin-right: 3px !important;
  }
}

.blur {
  filter: blur(1px);
  margin-right: 33px;
}

.success-tick {
  position: absolute;
  top: 120px;
}

.button-margin {
  position: absolute;
  left: 45%;
  z-index: 50;
  top: 91%;
}

.custom-department {
  width: 100%;
}
.department {
  height: 200px;
  width: auto;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 1px 3px 12px 0 rgba(151, 147, 147, 0.34);
  padding-top: 16px;
  margin-top: -40px;
  margin-left: 15px;
  position: absolute;
  z-index: 1;
}
li.list-department {
  padding: 6px;
  /* padding-right: 28px; */
  /* margin-left: -40px; */
  cursor: pointer;
  color: #36454f;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  /* line-height: 16px; */
  padding-left: 16px;
}
span.label-department {
  height: 20px;
  width: 83px;
  color: #36454f;
  font-family: "Poppins Medium";
  font-size: 18px;
  letter-spacing: 0.17px;
  line-height: 20px;
  /* text-align: center; */
  padding-left: 16px;
}
.department-input {
  box-sizing: border-box;
  height: 27px;
  width: 80%;
  border: 1px solid #cbdaf2;
  border-radius: 2px;
  margin-left: 14px;
  padding: 7px;
}
img.image-icon {
  cursor: pointer;
  float: revert;
  margin-left: 10px;
}
.scroll-department {
  height: 124px;
  overflow-y: scroll;
}
img.image-dep {
  position: absolute;
  top: 60px;
  right: 30px;
  float: right;
}
.add_select_dep {
  cursor: pointer;
  padding: 12px;
}
.department_dev {
  padding-top: 8px;
}
li.list-department:hover {
  color: #2758f6 !important;
  background-color: #e2e6ec !important;
}

.active_department {
  color: #2758f6 !important;
  background-color: #e2e6ec !important;
}
#department_add::placeholder {
  color: #36454f;
  font-family: Poppins;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
@media (min-width: 1441px) and (max-width: 2560px) {
  #personal-info {
    padding: 0 20px !important;
  }
  .button-save {
    margin-right: -2px;
  }
}
@media (max-width: 2560px) {
  .button-margin {
    left: 47% !important;
  }
}

@media (max-width: 1700px) {
  .button-margin {
    left: 46% !important;
  }
}

@media (max-width: 1440px) {
  .button-margin {
    left: 45% !important;
  }
}

@media (max-width: 1024px) {
  .button-margin {
    left: 42% !important;
  }
}

@media (max-width: 768px) {
  .button-margin {
    left: 39% !important;
  }
}

@media (max-width: 425px) {
  .button-margin {
    left: 30% !important;
  }
}

@media (max-width: 375px) {
  .button-margin {
    left: 26% !important;
  }
}

@media (max-width: 320px) {
  .button-margin {
    left: 20% !important;
  }
}

@media (min-width: 375px) and (max-width: 425px) {
  .top {
    top: 72% !important;
  }
}

@media (max-width: 320px) {
  .top {
    top: 68% !important;
  }
}

.button-width {
  width: 123px;
  margin-left: -31px;
}

.top {
  top: 77%;
}

.error {
  margin-top: 50px !important;
  background: none;
  float: unset;
}

@media (max-width: 400px) and (min-width: 350px) {
  .p-detuct {
    font-size: 12px;
  }
}

@media (max-width: 349px) and (min-width: 301px) {
  h5.account-setting {
    font-size: 12px;
  }

  .custom-control {
    padding-left: 0.5rem !important;
  }

  .p-detuct {
    font-size: 10px;
  }
}
.main-div-account {
  padding-right: 30px !important;
  padding-top: 37px !important;
}
@media (min-width: 300px) and (max-width: 440px) {
  .button-width {
    margin-left: 0px;
  }
}
@media (min-width: 441px) and (max-width: 500px) {
  .button-width {
    margin-left: -20px;
  }
}
@media (min-width: 700px) and (max-width: 800px) {
  .button-width {
    margin-left: 0px;
  }
}
</style>
